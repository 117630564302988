import React, { useState, useEffect, useContext } from 'react';
import { Autocomplete, TextField, Button, List, ListItem, ListItemText, Box, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiService from '../api/Services';
import { AppContext } from "../context/AppContext";
import { toast } from 'react-toastify';

export default function DefaultValue() {
    const { t } = useTranslation();
    const { defaultVal, setDefaultVal } = useContext(AppContext);

    const [resources, setResources] = useState([]);
	const [depots, setDepots] = useState([]);
	const [typeServices, setTypeServices] = useState([]);
    const [defaultValues, setDefaultValues] = useState({
        depot: null,
		vehicle: null,
		typeService: defaultVal.typeService
    });

    useEffect(() => {
		apiService.getVehicle().then(res => setResources(res.data));
		apiService.getSelectDepot().then(res => setDepots(res.data));
		apiService.getTypeService().then(res => setTypeServices(res.data))
	}, []);

    const handleSubmit = () => {
        setDefaultVal(defaultValues)
        toast.success(t('drawer.default_success'))
    };

    return (
		<Container component="main" maxWidth="xs">
			<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '85vh', justifyContent:'center'}}>
				<Autocomplete
					disablePortal
					fullWidth
					sx={{ mt: 2 }}
					getOptionLabel={(option) => option.plate+" - "+option.driver.first_name+" "+option.driver.last_name}
					onChange={(event, newValue) => setDefaultValues({ ...defaultValues, vehicle: newValue })}
					renderOption={(props, option) => (
						<List  {...props} key={option.plate}>
							<ListItem>
								<ListItemText primary={option.plate} secondary={option.driver.first_name+" "+option.driver.last_name} />
							</ListItem>
						</List>
					)}
					options={resources}
					renderInput={(params) => (
						<TextField
							{...params}
							color="secondary"
							label={t('services.resources')}
							placeholder={defaultVal.vehicle ? `${defaultVal.vehicle.plate} - ${defaultVal.vehicle.driver.first_name} ${defaultVal.vehicle.driver.last_name}` : ''}
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
				/>
				<Autocomplete
					autoHighlight={true}
					fullWidth
					sx={{ mt: 2 }}
					getOptionLabel={(option) => option.name}
					onChange={(event, newValue) => setDefaultValues({ ...defaultValues, depot: newValue })}
					renderOption={(props, option) => (
						<List  {...props}>
							<ListItem>
								<ListItemText primary={option.name} />
							</ListItem>
						</List>
					)}
					options={depots}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('services.depot')}
							placeholder={defaultVal.depot ? defaultVal.depot.name : ''}
							color="secondary"
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
				/>
				<Autocomplete
					fullWidth
					sx={{ mt: 2 }}
					multiple
					getOptionLabel={(option) => option.name}
					options={typeServices}
					value={defaultValues.typeService}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					onChange={(event, newValue) => setDefaultValues({ ...defaultValues, typeService: newValue })}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('services.service_type')}
							color="secondary"
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
				/>
				<Button fullWidth color="secondary" variant="contained" size="large" onClick={handleSubmit} sx={{ mt: 3 }}>
		 			{t('btn.save')}
		 		</Button>
			</Box>
		</Container>
    );
};