import {
	useRoutes,
	Navigate
} from "react-router-dom";
import AppState from "./context/AppState";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// layouts
import UserLayout from './layouts/UserLayout';
import BaseLayout from './layouts/BaseLayout';
// pages
import Login from './pages/Login';
import Service from './pages/Service';
import { AppContext } from "./context/AppContext";
import { useContext } from "react";
import NotFound from "./components/NotFound";
import { Alert, Snackbar } from "@mui/material";
import { Detector } from "react-detect-offline";
import { useTranslation } from 'react-i18next';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';
import Company from "./pages/Company";
import DefaultValue from "./components/DefaultValue";

const App = () => {
	const { auth } = useContext(AppContext)
	// ----------------------------------------------------------------------
	let routes = useRoutes([
		{
			path: '/dashboard',
			element: auth ? <UserLayout /> : <Navigate to="/login" replace />,
			children: [
				{ path: 'services', element: <Service /> },
				{ path: 'companies', element: <Company /> },
				{ path: 'default', element: <DefaultValue /> }
			]
		},
		{
			path: '/',
			element: <BaseLayout />,
			children: [
				{ path: 'login', element: <Login /> },
				{ path: 'login', element: <Login /> },
				{ path: '404', element: <NotFound /> },
				{ path: '/', element: <Navigate to="/dashboard" /> },
				{ path: '*', element: <Navigate to="/404" /> }
			]
		},
		{ path: '*', element: <Navigate to="/404" replace /> }
	]);
	return routes;
};


const OfflineSnackbar = ({ online }) => {
	const { t } = useTranslation();
	const anchorOrigin = {
		vertical: 'top',
		horizontal: 'center',
	}
	const { vertical, horizontal } = anchorOrigin;

	return (
		<Snackbar open={!online ? true : false} anchorOrigin={{ vertical, horizontal }}>
			<Alert icon={<SignalWifiConnectedNoInternet4Icon fontSize="inherit" />} severity="error" sx={{ width: '100%' }}>
				{t('offline')}
			</Alert>
		</Snackbar>
	)
}

const Router = () => {
	return (
		<AppState>
			<Detector render={({ online }) => (<OfflineSnackbar online={online} />)} />
			<ToastContainer />
			<App />
		</AppState >
	);
};

export default Router;