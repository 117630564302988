import { Autocomplete, Grid, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import { changeCompany } from '../api/Company'
import { getUserData } from '../api/Login'
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Detector } from "react-detect-offline";
import { useNavigate } from "react-router-dom";

const CompanyForm = () => {
	const navigate = useNavigate();
	const { t } = useTranslation()
	const { companies, auth, setAuth, setCompanies, setUser, setDefaultVal } = useContext(AppContext)
	const [loading, setLoading] = useState(false)
	const [companyID, setCompanyID] = useState()
	const [roleID, setRoleID] = useState()
	const [roles, setRoles] = useState([])
	const [resetRole, setResetRole] = useState(null)

	const handleCompany = (data) => {
		setCompanyID(data.id)
		setResetRole(null)
		setRoles(data.roles)
	}

	const handleRoles = (data) => {
		setRoleID(data.id)
		setResetRole(data)
	}

	const submit = async () => {
		setLoading(true)
		await changeCompany(auth.user_uuid, companyID, roleID).then(res => {
			setAuth(res.data.token)
		}).catch(err => {
			toast.error(JSON.stringify(err.response.data))
			setLoading(false)
		})
		await getUser()
	}

	const getUser = () => {
		getUserData(sessionStorage.getItem('userID')).then(res => {
			const data = { ...res.data };
			setCompanies(data.companies)
			if(data.company.web_config.default_values_pwa) {
				const { vehicle, depot, type_service } = data.company.web_config.default_values_pwa
				setDefaultVal({ 
					vehicle: JSON.stringify(vehicle) === '{}' ? null : vehicle,
					depot: JSON.stringify(vehicle) === '{}' ? null : depot,
					typeService: type_service
				})
			}
			delete data.companies
			setUser(data)
			toast.success(t('companies.success'))
			setLoading(false)
			navigate('/dashboard/services');
		}).catch(err => {
			setLoading(false)
			toast.error(JSON.stringify(err.response.data))
		})
	}


	return (
		<Grid container direction="row" justifyContent="center" spacing={4}>
			<Grid item xs={10} md={12} sx={{ textAlign: 'center', my: 5 }}>
				<Typography variant="h6" >
					{t('companies.title')}
				</Typography>
			</Grid>
			<Grid item xs={10} md={12}>
				<Autocomplete
					disablePortal
					disableClearable
					isOptionEqualToValue={(option, value) => option.id === value.id}
					getOptionLabel={(option) => option.name}
					onChange={(event, newValue) => { handleCompany(newValue) }}
					renderOption={(props, option) => (
						<List  {...props}>
							<ListItem>
								<ListItemText primary={option.name} />
							</ListItem>
						</List>

					)}
					options={companies}
					renderInput={(params) => (
						<TextField
							color='secondary'
							{...params}
							label={t('companies.companies')}
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
				/>

			</Grid>
			<Grid item xs={10} md={12}>
				<Autocomplete
					disablePortal
					disableClearable
					value={resetRole}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					getOptionLabel={(option) => option.name}
					onChange={(event, newValue) => { handleRoles(newValue) }}
					renderOption={(props, option) => (
						<List  {...props}>
							<ListItem>
								<ListItemText primary={option.name} />
							</ListItem>
						</List>

					)}
					options={roles}
					renderInput={(params) => (
						<TextField
							color='secondary'
							{...params}
							label={t('companies.roles')}
							InputProps={{
								...params.InputProps,
							}}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={10} md={12}>
				<Detector
					render={({ online }) => (
						<LoadingButton 
							loading={loading} 
							disabled={!online} 
							variant="contained" 
							fullWidth color="secondary" 
							size="large" 
							onClick={submit}
						>
							{t('btn.save')}
						</LoadingButton>
					)}
				/>
			</Grid>
		</Grid >
	)
}

export default CompanyForm