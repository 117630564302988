const validateErrorResponse = (error, t) => {
    let hasErrorStatus = false
    let errorMessage = ''
    if (error.response) {
        let statusError = error.response.status
        if (statusError) {
            if (statusError === 400) {
                hasErrorStatus = true
                let errorObject = error.response.data
                for (const moduleName in errorObject) {
                    // eslint-disable-next-line
                    errorObject[moduleName].forEach(errorCode => {
                        if (errorCode.includes(':')) {
                            const split = errorCode.split(':')
                            errorMessage = `${t('bad_request_errors.' + split[0])} ${t('bad_request_errors.' + split[1])}`
                        } else {
                            errorMessage = `${t('bad_request_errors.' + errorCode)}`
                        }
                    })
                }
            }
            if (statusError === 404) {
                hasErrorStatus = true
                errorMessage = `${('bad_request_errors.not_found')}`
            }
        }
    }
    if (!hasErrorStatus) {
        errorMessage = `${('bad_request_errors.general')}`
    }
    return errorMessage
}

export { validateErrorResponse };