const Reducer = (state, action) => {

	const { type, payload } = action

	switch (type) {
		case 'SET_AUTH':
			return {
				...state,
				auth: payload
			}
		case 'SET_USER':
			return {
				...state,
				user: payload
			}
		case 'SET_COMPANIES':
			return {
				...state,
				companies: payload
			}
		case 'SET_DEFAULT':
			return {
				...state,
				defaultValues: payload
			}
		default:
			return state
	}

}

export default Reducer