import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import ServiceForm from '../components/ServiceForm';
import { AppContext } from "../context/AppContext";
import { useTranslation } from 'react-i18next';

const Service = () => {
	const { user } = useContext(AppContext);
	const { t } = useTranslation();
	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
		>
			<Grid item xs={12} sm={10} md={8} lg={6} xl={5}>
				{user.company.current_role.permissions.service.includes('add') ? <ServiceForm /> 
				: <Typography>{t('session.unauthorized')}</Typography>}
			</Grid>
		</Grid>
	);
}

export default Service;