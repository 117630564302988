import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      // main: '#fdd402',
      main: '#4a6a86'
    },
    secondary: {
      // main: '#ec536c',
      main: '#fdd402'
    },
    success: {
      main: '#38c57f'
    },
    error: {
      main: '#ec536c'
    }
  },
});

export default theme;
