import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import Router from './routes'
import HttpCommon from './http-common'
import axios from 'axios'
import "./i18n";
import './index.css';
import swDev from './swDev';
swDev();

HttpCommon.init();

axios.interceptors.response.use(response => successHandler(response), error => errorHandler(error))

const successHandler = response => {
	return response
}

const errorHandler = error => {
	var status = { ...error }
	if (status.response?.status === 401) {
		sessionStorage.clear()
		window.location = '/'
	}
	return Promise.reject({ ...error })
}

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<Router />
		</BrowserRouter>	
	</ThemeProvider>,
	document.getElementById('root')
);