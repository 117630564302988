import React from 'react';
import { Grid } from '@mui/material';
import CompanyForm from '../components/CompanyForm'

const Company = () => {
	return (
		<Grid
			container
			direction="row"
			justifyContent="center"
		>
			<Grid item xs={12} sm={8} md={5} lg={4} xl={3}>
				<CompanyForm />
			</Grid>
		</Grid>
	);
}

export default Company;