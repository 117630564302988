import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { Icon } from "leaflet";
import pointmap from '../assets/images/icon/pointmap.png';
import "leaflet/dist/leaflet.css";

const pointMap = new Icon({
  iconUrl: pointmap,
  iconSize: [24, 43],
  iconAnchor: [12, 40]
});

function LocationMarker({ setCoords }) {
    const [position, setPosition] = useState(null);
    useMapEvents({
        click(e) {
            setPosition(e.latlng);
            setCoords(e.latlng);
        }
    });
    return position === null ? null : <Marker position={position} icon={pointMap} />
};

export default function MapLeaflet({ coordinates, setCoords }) {
    return (
        <MapContainer center={[coordinates[1], coordinates[0]]} zoom={10} maxZoom={18} scrollWheelZoom={true}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker setCoords={setCoords} />
        </MapContainer>
    );
};
