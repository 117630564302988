import React, { useEffect, useState, useContext, Fragment, useMemo } from 'react';
import { Autocomplete, 
	Checkbox, 
	Divider, 
	FormControl, 
	FormControlLabel, 
	Grid, 
	IconButton, 
	InputBase, 
	InputLabel, 
	List, 
	ListItem, 
	ListItemText, 
	MenuItem, 
	Paper, 
	Select, 
	TextField, 
	Chip,
	FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { AppContext } from "../context/AppContext";
import SearchIcon from '@mui/icons-material/Search';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
import { useTranslation } from 'react-i18next';
import apiService from '../api/Services';
import { toast } from 'react-toastify';
import AdditionalFields from './AdditionalFields';
import { validateErrorResponse } from './Errors';
import MapLeaflet from './Map';

// const options = {
//   enableHighAccuracy: true,
//   timeout: 5000,
//   maximumAge: 0
// };

export default function ServiceForm() {

	const { user, defaultVal } = useContext(AppContext);
	const consecutive = user.company.web_config.service_order_settings.consecutive;
	const default_values_form = {
		additional_fields: {},
		city: "",
		client_document: "",
		client_document_type: "",
		client_email: "",
		client_name: "",
		client_phone_number: "",
		cubing_variables: [],
		date: new Date().toISOString().slice(0, 10),
		delivery_address: "",
		depot: defaultVal.depot ? defaultVal.depot.code : "",
		description: "",
		latitude: "",
		load_time: "",
		longitude: "",
		neighborhood: "",
		product_types: defaultVal.typeService.length > 0 ? [defaultVal.typeService[0].id] : '',
		receiver: "",
		seller_depot: "",
		service_order: "",
		time_windows: [],
		type_service: defaultVal.typeService.length > 0 ? [defaultVal.typeService[0].id] : '',
		vehicle: defaultVal.vehicle ? defaultVal.vehicle.plate : "",
		weight: "",
		zone: "",
	};
	const default_values_field = {
		addresses: [],
		defaultZone: null,
		disabledField: true,
		driver: defaultVal.vehicle ? `${defaultVal.vehicle.driver.first_name} ${defaultVal.vehicle.driver.last_name}` : '',
		zone: [],
		depotSeller: [],
		depot: [],
		addressesSearch: {},
		resourses: [],
		typeDocument: [],
		nameOrDocument: [],
		dateService: new Date(),
		time_window: {
			end_time: null,
			start_time: null
		},
		showMap: false
	}
	const { t, i18n } = useTranslation();

	const [name, setName] = useState('');
	const [document, setDocument] = useState('');
	const [cityName, setCityName] = useState('');
	const [address, setAddress] = useState('');
	const [nameOrDocument, setNameOrDocument] = useState([]);
	const [typeDocument, setTypeDocument] = useState([]);
	const [resources, setResources] = useState([]);
	const [populatedCenters, setPopulatedCenters] = useState([]);
	const [depot, setDepot] = useState(defaultVal.depot);
	const [resource, setResource] = useState(defaultVal.vehicle);
	const [depots, setDepots] = useState([]);
	const [typeService, setTypeService] = useState(defaultVal.typeService[0]);
	const [typeServices, setTypeServices] = useState([]);
	const [cubing, setCubing] = useState([]);
	const [additionalFields, setAdditionalFields] = useState([]);
	const [loading, setLoading] = useState({
		addressSearch: false,
		submit: false
	});
	const [field, setField] = useState(default_values_field);
	const [form, setForm] = useState(default_values_form);
	const [serviceFields, setServiceFields] = useState({
        zone: { show: true },
        weight: { show: true },
        vehicle: { show: true },
        latitude: { show: true },
        load_time: { show: true },
        longitude: { show: true },
        description: { show: true },
        client_email: { show: true },
        seller_depot: { show: true },
        time_windows: { show: true }
    });
	const validations = useMemo(() => {
		const val = {
			service_order: { required: !consecutive },
			client_document_type: { required: true },
			client_document: { required: true },
			client_name: { required: true },
			client_phone_number: { required: false, min: 7 },
			city: { required: true },
			delivery_address: { required: true },
			latitude: { required: true },
			longitude: { required: true },
			depot: { required: true },
			type_service: { required: true },
			load_time: { required: true },
			time_windows: { required: true },
			weight: { required: true }
		};
		Object.keys(serviceFields).forEach(el => {
			if(val[el]) val[el].required = serviceFields[el].show && val[el].required;
		});
		return val;
	}, [serviceFields, consecutive]);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		apiService.getDocumentType().then(res => setTypeDocument(res.data));
		apiService.getVehicle().then(res => {
			const data = res.data.map(el => {
				if(el.driver.id === user.id) el.group = 'services.me'
				else el.group = 'services.others'
				return el 
			}).sort((x,y)=> -y.group[9].localeCompare(x.group[9]))
			setResources(data)
		});
		apiService.getSelectDepot().then(res => setDepots(res.data));
		apiService.getTypeService().then(res => setTypeServices(res.data));
		apiService.getBaseFields().then(res => setServiceFields(res.data.values));
		apiService.getCubingVariables().then(res => {
			res.data.forEach(el => el.value = '');
			setCubing(res.data)
		});
		apiService.getAdditionalFields().then(res => {
			res.data.forEach(el => el.field_type === 'boolean' ? el.value = false : el.value = el.default_value);
			setAdditionalFields(res.data.filter(el => el.information.send_to_app_as === 'read'));
		});
	}, [user]);

	useEffect(() => {
		if (name.length >= 3) {
			apiService.predictionDocumentName({
				filter: name, param: 'name'
			}).then(res => {
				setNameOrDocument(res.data)
			})
		}
	}, [name])

	useEffect(() => {
		if(document.length >= 3) {
			apiService.predictionDocumentName({
				filter: document, param: 'document'
			}).then(res => {
				setNameOrDocument(res.data)
			})
		}
	}, [document])

	useEffect(() => {
		if(cityName.length >= 3) {
			apiService.getPopulationCenter(cityName).then(res => {
				setPopulatedCenters(res.data)
			})
		}
	}, [cityName])

	const handleAutocompleteChange = (data, fieldName, event) => {
		if (typeof data === 'object') {
			const cloneObj = {
				client_name: data.name || "",
				client_email: data.email || "",
				client_document_type: data.document_type.id || "",
				client_document: data.document || "",
				client_phone_number: data.phone_number || "",
				load_time: data.load_time || "",
			}
			if(fieldName === 'name') setDocument(data.document)
			else setName(data.name)
			setField({ ...field, addresses: data.addresses })
			setForm({ ...form, ...cloneObj })
		} else {
			if(event) setForm({ ...form, [event.target.name]: data })
		}
	}

	const handleAutocompleteAdressChange = (data) => {
		setAddress(data.address);
		setField({ ...field, disabledField: false, });
		let cloneObj = {
			delivery_address: data.address || "",
			city: data.city.id || "",
			latitude: data.latitude || "",
			longitude: data.longitude || "",
			neighborhood: data.neighborhood || "",
			time_windows: data.time_windows || []
		};
		setCityName(data.city.name);
		setForm({ ...form, ...cloneObj });
		const city = field.zone.filter(e => e.city_code === data.city.code)[0];
		cloneObj = {
			disabledField: false,
			defaultZone: city
		};
		setField({ ...field, ...cloneObj });
	}

	const handleAutocompleteCityChange = (data) => {
		setForm({ ...form, city: data.id ? data.id : '' })
		setField({ ...field, defaultZone: data })
	}

	// function success(pos) {
	// 	const crd = pos.coords;
	// 	const cloneObj = {
	// 		longitude: crd.longitude.toFixed(6),
	// 	   	latitude: crd.latitude.toFixed(6)
	//    }
	//    setForm({ ...form, ...cloneObj })
	// 	toast.success(t('geo.located'))
	//   };
	// function error(err) {
	// 	toast.error(t('geo.error'))
	// };
	const handleAutocompleteAdressSearchChange = (searchAddress) => {
		if(searchAddress) {
			if(field.defaultZone && form.delivery_address) {
				toast.success(t('geo.locating'))
				setLoading({ ...loading, addressSearch: true })
				const code = field.defaultZone.city_code;
				const address = form.delivery_address
				apiService.getsServiceAddress(code, address).then(res => {
					const cloneObj = {
				 		longitude: res.data.cx,
						latitude: res.data.cy,
						neighborhood: res.data.barrio === 'null' ? '' : res.data.barrio
					}
					setForm({ ...form, ...cloneObj })
					toast.success(t('geo.located'))
				}).catch(e => toast.error(t('geo.error')))
				.finally(() => setLoading({ ...loading, addressSearch: false }))
			} else {
				toast.error(t('geo.fields_required'))
			}
		} else {
			toast.success(t('geo.select_point_on_map'))
			setField({ ...field, showMap: true })
			// navigator.geolocation.getCurrentPosition(success, error, options);
		}
	}

	const handleAutocompleteResourceChange = (data) => {
		setForm({ ...form, vehicle: data.plate })
		setField({ ...field, driver: `${data.driver.first_name} ${data.driver.last_name}` })
		setResource(data)
	}

	const handleAutocompleteCediChange = (data) => {
		setDepot(data)
		setForm({ ...form, depot: data.code })
	}

	const handleAutocompleteTypeService = (data) => {
		const id = data ? data.id : ''
		setForm({ ...form, type_service: [id], product_types:[ data.id] })
		setTypeService(data)
	}

	const handleDateChange = (data) => {
		setField({ ...field, dateService: data })
		setForm({ ...form, date: new Date(data).toISOString().slice(0, 10) })
	}

	const handleMapCoordinates = (coordinates) => {
		setForm({ ...form, latitude: coordinates.lat.toFixed(10), longitude: coordinates.lng.toFixed(10) })
	}

	const handleTimeWindows = (data, fieldName) => {
		const time_window = field.time_window;
		time_window[fieldName] = data;
		setField({ ...field, time_window });
	};
	const addTimeWindow = () => {
		try {
			const time_window = {};
			time_window.start_time = field.time_window.start_time.toTimeString().slice(0,5);
			time_window.end_time = field.time_window.end_time.toTimeString().slice(0,5);
			if(field.time_window.end_time > field.time_window.start_time) {
				const { time_windows, ...erros } = errors;
				setErrors(erros);
				setField({ ...field, time_window: { end_time: null, start_time: null } });
				setForm({ ...form, time_windows: form.time_windows.concat(time_window) });
			} else {
				toast.error(t('time_windows.error_time'));
			}
		} catch (error) {
			toast.error(t('time_windows.error_null'));
		}
	};
	const deleteTimeWindow = (i) => {
		const time_windows = form.time_windows.filter((e,index) => index !== i);
		setForm({ ...form, time_windows });
	};

	const handleChangeCubing = (index, value) => {
		const cub = cubing.slice();
		cub[index].value = value;
		setCubing(cub); 
	};
	const handleAdditionalField = (index, value) => {
		const addF = additionalFields.slice();
		addF[index].value = value;
		setAdditionalFields(addF);
	};

	const validateAdditionalFields = {
		'string': ({required, min_length, max_length}, value) => {
			let nextstep = 0;
			let error = -1
			if(required) value.length ? nextstep = 1 : error = {type: 'required'}
			else value.length ? nextstep = 1 : nextstep = 4;
			if(nextstep === 1){ 
				if(min_length.length) value.length >= Number(min_length) ? nextstep = 2 : error = {type: 'min_length'}
				else nextstep = 2;
			};
			if(nextstep === 2) {
				if(max_length.length) value.length <= Number(max_length) ? nextstep = 3 : error = {type: 'max_length'}
				else nextstep = 3;
			};
			if(nextstep === 4) return {error , value: ''};
			if(error) return {error , value: value};
		},
		'number': ({required, min, max, decimal_length}, value) => {
			let nextstep = 0;
			let error = -1
			if(required) value.length ? nextstep = 1 : error = {type: 'required'}
			else value.length ? nextstep = 1 : nextstep = 4;

			if(nextstep === 1){ 
				if(min.length) Number(value) >= Number(min) ? nextstep = 2 : error = {type: 'min'}
				else nextstep = 2;
			};
			if(nextstep === 2) {
				if(max.length) Number(value) <= Number(max) ? nextstep = 3 : error = {type: 'max'}
				else nextstep = 3;
			};
			if(nextstep === 4) return {error , value: ''};
			if(error) return {error , value: Number(value).toFixed(decimal_length)};
		},
		'boolean': (rules, value) => { return {error: -1, value: value} },
		'date' : ({required}, value) => { 
			let nextstep = 0
			let error = -1
			if(required) value.length ? nextstep = 1 : error = {type: 'required'}

			if(nextstep === 1){ 
				const [year, month, day] = value.split("-").map(Number);
				let date = new Date(year, month - 1, day);
				if(date instanceof Date && !isNaN(date)) nextstep = 2
				else error = {type: 'required'}
			};
			if(nextstep === 2){
				const [year, month, day] = value.split("-").map(Number);
				let date = new Date(year, month - 1, day);
				let formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
				return {error, value: formattedDate}
			}
			return {error, value: ''} 
		},
		'email': ({required}, value) => {
			let nextstep = 0;
			let error = -1

			var regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
			if(required) value.length ? nextstep = 1 : error = {type: 'required'}
			else value.length ? nextstep = 1 : nextstep = 3

			if(nextstep === 1){ 
				if(value.length) {
					regex.test(value) ? nextstep = 2 : error = {type: 'email_invalid'}
				}
				else nextstep = 3;
			} if(nextstep === 2){
				return {error, value: value}
			}

			if(error){
				return {error , value: value};
			}
			
		},
	};

	const handleReset = () => {
		setName('');
		setDocument('');
		setCityName('');
		setAddress('');
		setErrors({});
		setDepot(defaultVal.depot);
		setResource(defaultVal.vehicle);
		setTypeService(defaultVal.typeService[0]);
		setForm(default_values_form);
		setField(default_values_field);
	};

	const handleOnSubmit = (e) => {
		let erros = {};
		const cubing_variables = [];
		cubing.forEach(el => {
			if(el.value.length > 0 && el.value.length < 10){
				let value = Number(el.value);
				if(value > 0) {
					el.decimal ? value = value.toFixed(2) : value.toFixed(0);
					cubing_variables.push({ id: el.id, value });
				} else {
					erros[el.id] = { type : 'must_be_greater'}
				}
			} else {
				el.value.length > 0 ? erros[el.id] = { type : 'to_big'} : erros[el.id] = { type : 'required'};
			}
		});
		// --------validate additional fields-------------
		let addF = {};
		additionalFields.forEach(el => {
			let type = 'string'
			if(el.field_type === 'boolean') type='boolean';
			if(el.field_type === 'int' || el.field_type === 'float') type='number';
			if(el.field_type === 'date') type='date';
			if(el.field_type === 'email') type='email';
			const val = validateAdditionalFields[type](el.rules,el.value);
			if(val.error === -1) addF[el.field_name] = val.value;
			else erros[el.id] = val.error
		});
		// --------validate base fields-------------------
		const data = Object.assign({}, form);
		if(data.city.length === 0) data.city = cityName;
		if(data.client_document.length === 0) data.client_document = document;
		if(data.client_name.length === 0) data.client_name = name;
		Object.keys(validations).forEach(el => {
			const min_length = validations[el].min ? validations[el].min : 1
			if(validations[el].required && data[el].length < min_length) {
				erros[el] = { type: 'required' }
			}
			if(data[el].length > 0 && validations[el].min && data[el].length < min_length) {
				erros[el] = { type: 'min_length' }
			}
		});
		if(data.weight === '') data.weight = 0;
		if(data.vehicle === '') data.vehicle = null;
		if(data.zone === '') data.zone = null;
		if(Object.keys(erros).length > 0) {
			toast.error(t('errors.invalid_values'))
			setErrors(erros)
		}
		else {
			data.additional_fields = addF;
			data.cubing_variables = cubing_variables;
			data.stops = [];
			setLoading({ ...loading, submit: true });
			apiService.newService(data).then(() => {
				setLoading({ ...loading, submit: false });
				toast.success(t('services.created'))
			})
			.catch(e => {
				setLoading({ ...loading, submit: false });
				const message = validateErrorResponse(e, t);
				toast.error(message);
			})
			.finally(() => {
				handleReset();
			})
		}
	}

	return (
		<Grid container direction="row" justifyContent="center" spacing={4}>
			<Grid item xs={10} md={12}>
				<TextField
					fullWidth
					color="secondary"
					label={consecutive ? t('services.automatic_guide') : t('services.guide')}
					value={form.service_order}
					onChange={(e) => { setForm({ ...form, service_order: e.target.value }) }}
					disabled={consecutive}
					error={errors.service_order ? true : false}
					helperText={errors.service_order ? t('errors.required') : ''}
				/>
			</Grid>
			<Grid item xs={10} md={6} >
				<FormControl fullWidth error={errors.client_document_type ? true : false}>
					<InputLabel 
						color="secondary"
						id="type-document"
					>{t('services.type_document')}</InputLabel>
					<Select
						labelId="type-document"
						label={t('services.type_document')}
						value={form.client_document_type}
						defaultValue=""
						onChange={(e) => setForm({ ...form, client_document_type: e.target.value })}
					>
						{
							typeDocument.map(item => {
								return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
							})
						}
					</Select>
					<FormHelperText>{errors.client_document_type ? t('errors.required') : ''}</FormHelperText>
				</FormControl>
			</Grid>
			<Grid item xs={10} md={6}>
				<Autocomplete
					fullWidth
					disableClearable
					freeSolo
					inputValue={document}
					onInputChange={(event, newInputValue) => { setDocument(newInputValue) }}
					autoHighlight={true}
					options={nameOrDocument}
					getOptionLabel={(option) => option.document || ''}
					renderOption={(props, option) => (
						<List  {...props} key={option.id}>
							<ListItem>
								<ListItemText primary={`${option.document_type.slug} ${option.document}`} secondary={option.name} />
							</ListItem>
						</List>
					)}
					onChange={(event, newValue) => { handleAutocompleteChange(newValue, 'document') }}
					renderInput={(params) => (
						<TextField
							{...params}
							color="secondary"
							label={t('services.document')}
							name="client_document"
							InputProps={{
								...params.InputProps,
							}}
							error={errors.client_document ? true : false}
							helperText={errors.client_document ? t('errors.required') : ''}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={10} md={6} >
				<Autocomplete
					fullWidth
					freeSolo
					inputValue={name}
					onInputChange={(event, newInputValue) => { setName(newInputValue) }}
					autoHighlight={true}
					disableClearable
					options={nameOrDocument}
					getOptionLabel={(option) => option.name || ''}
					renderOption={(props, option) => (
						<List  {...props} key={option.id}>
							<ListItem>
								<ListItemText primary={option.name} secondary={`${option.document_type.slug} ${option.document}`} />
							</ListItem>
						</List>

					)}
					onChange={(event, newValue) => { handleAutocompleteChange(newValue, 'name') }}
					renderInput={(params) => (
						<TextField
							{...params}
							color="secondary"
							label={t('services.name')}
							name="client_name"
							InputProps={{
								...params.InputProps,
							}}
							error={errors.client_name ? true : false}
							helperText={errors.client_name ? t('errors.required') : ''}
						/>
					)}
				/>
			</Grid>
			{serviceFields.client_email.show ? (
				<Grid item xs={10} md={6}>
					<TextField
						fullWidth
						color="secondary"
						label={t('services.email')}
						value={form.client_email}
						onChange={e => setForm({ ...form, client_email: e.target.value })}
						error={errors.client_email ? true : false}
						helperText={errors.client_email ? t('errors.required') : ''}
					/>
				</Grid>
			) : null}
			<Grid item xs={10} md={6} >
				<TextField
					fullWidth
					color="secondary"
					label={t('services.phone')}
					value={form.client_phone_number}
					onChange={e => setForm({ ...form, client_phone_number: e.target.value })}
					error={errors.client_phone_number ? true : false}
					helperText={errors.client_phone_number ? t(`errors.${errors.client_phone_number.type}`) : ''}
				/>
			</Grid>
			<Grid item xs={10} md={6}>
				<Autocomplete
					disableClearable
					autoHighlight={true}
					getOptionLabel={(option) => option.address}
					inputValue={address}
					onChange={(event, newValue) => { handleAutocompleteAdressChange(newValue) }}
					renderOption={(props, option) => (
						<List  {...props}>
							<ListItem>
								<ListItemText primary={option.address} secondary={`${option.city.name} - ${option.department.name}`} />
							</ListItem>
						</List>
					)}
					options={field.addresses}
					renderInput={(params) => (
						<TextField
							{...params}
							color="secondary"
							label={t('services.client_address')}
							InputProps={{
								...params.InputProps,
							}}
							error={errors.delivery_address ? true : false}
							helperText={errors.delivery_address ? t('errors.required') : ''}
						/>
					)}
				/>
			</Grid>
			{serviceFields.vehicle.show ? (
				<Grid item xs={10} md={6} >
					<Autocomplete
						disablePortal
						disableClearable
						value={resource}
						isOptionEqualToValue={(option, value) => option.plate === value.plate}
						getOptionLabel={(option) => option.plate}
						onChange={(event, newValue) => { handleAutocompleteResourceChange(newValue) }}
						renderOption={(props, option) => (
							<List  {...props} key={option.plate}>
								<ListItem>
									<ListItemText primary={option.plate} secondary={option.weight_available} />
								</ListItem>
							</List>
						)}
						groupBy={(option) => t(option.group)}
						options={resources}
						renderInput={(params) => (
							<TextField
								{...params}
								color="secondary"
								label={t('services.resources')}
								InputProps={{
									...params.InputProps,
								}}
							/>
						)}
					/>					
				</Grid>
			) : null}
			{serviceFields.vehicle.show ? (
				<Grid item xs={10} md={6} >
					<TextField
						readOnly
						fullWidth
						color="secondary"
						label={t('services.driver')}
						value={field.driver}
					/>					
				</Grid>
			) : null}
			<Grid item xs={10} md={6} >
				<Autocomplete
					fullWidth
					disableClearable
					freeSolo
					inputValue={cityName}
					onInputChange={(event, newInputValue) => { setCityName(newInputValue) }}
					autoHighlight={true}
					options={populatedCenters}
					getOptionLabel={(option) => option.city || ''}
					renderOption={(props, option) => (
						<List  {...props} key={option.id}>
							<ListItem>
								<ListItemText primary={option.city} secondary={`${option.departament} ${option.municipality}`}/>
							</ListItem>
						</List>
					)}
					onChange={(event, newValue) => { handleAutocompleteCityChange(newValue) }}
					renderInput={(params) => (
						<TextField
							{...params}
							color="secondary"
							label={t('services.populated_center')}
							name="client_city"
							InputProps={{
								...params.InputProps,
							}}
							error={errors.city ? true : false}
							helperText={errors.city ? t('errors.required') : ''}
						/>
					)}
				/>
			</Grid>
			{serviceFields.weight.show ? (
				<Grid item xs={10} md={6} >
					<TextField
						fullWidth
						color="secondary"
						label={t('services.weight')}
						value={form.weight}
						onChange={e => setForm({ ...form, weight: e.target.value })}
						error={errors.weight ? true : false}
						helperText={errors.weight ? t('errors.required') : ''}
					/>
				</Grid>
			) : null}
			<Grid item xs={10} md={12} >
				<FormControlLabel control={<Checkbox color="secondary" checked={field.disabledField} onChange={e => { setField({ ...field, disabledField: e.target.checked }) }} />} label={t('services.check')} />
			</Grid>
			<Grid item xs={10} md={12} >
				<Paper
					component="form"
					sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
				>
					<InputBase
						disabled={field.disabledField === false ? true : false}
						sx={{ ml: 1, flex: 1 }}
						placeholder={t('services.address')}
						inputProps={{ 'aria-label': 'search google maps' }}
						value={form.delivery_address}
						onChange={e => { setForm({ ...form, delivery_address: e.target.value }) }}
					/>
					<LoadingButton
						disabled={field.disabledField === false ? true : false}
						onClick={e => handleAutocompleteAdressSearchChange(true)}
						loading={loading.addressSearch}
						variant="text"
						sx={{ p: '10px' }} aria-label="search"
					>
						<SearchIcon />
					</LoadingButton>
					<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
					<IconButton disabled={field.disabledField === false ? true : false} onClick={e => handleAutocompleteAdressSearchChange(false)} color="primary" sx={{ p: '10px' }} aria-label="location">
						<MyLocationIcon />
					</IconButton>
				</Paper>
			</Grid>
			{field.showMap ? (
				<Grid item xs={10} >
					<MapLeaflet 
						coordinates={field.defaultZone ? field.defaultZone.coordinates : user.company.country.coordinates.coordinates}
						setCoords={(coord) => handleMapCoordinates(coord)}
					/>
				</Grid>
			) : null}
			{serviceFields.latitude.show ? (
				<Grid item xs={5} md={6} >
					<TextField
						disabled={field.disabledField === false ? true : false}
						fullWidth
						label={t('services.latitude')}
						color="secondary"
						value={form.latitude}
						onChange={e => setForm({ ...form, latitude: e.target.value })}
						error={errors.latitude ? true : false}
						helperText={errors.latitude ? t('errors.required') : ''}
					/>					
				</Grid>
			) : null}
			{serviceFields.longitude.show ? (
				<Grid item xs={5} md={6} >
					<TextField
						disabled={field.disabledField === false ? true : false}
						fullWidth
						label={t('services.longitude')}
						color="secondary"
						value={form.longitude}
						onChange={e => setForm({ ...form, longitude: e.target.value })}
						error={errors.longitude ? true : false}
						helperText={errors.longitude ? t('errors.required') : ''}
					/>					
				</Grid>
			) : null}
			<Grid item xs={10} md={6}>
				<Autocomplete
					disableClearable
					autoHighlight={true}
					getOptionLabel={(option) => option.name}
					value={depot}
					onChange={(event, newValue) => { handleAutocompleteCediChange(newValue) }}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					renderOption={(props, option) => (
						<List  {...props}>
							<ListItem>
								<ListItemText primary={option.name} />
							</ListItem>
						</List>
					)}
					options={depots}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('services.depot')}
							color="secondary"
							InputProps={{
								...params.InputProps,
							}}
							error={errors.depot ? true : false}
							helperText={errors.depot ? t('errors.required') : ''}
						/>
					)}
				/>
			</Grid>
			{serviceFields.zone.show ? (
				<Grid item xs={10} md={6}>
					<Autocomplete
						disableClearable
						autoHighlight={true}
						getOptionLabel={(option) => option.name}
						onChange={(event, newValue) => { setForm({ ...form, zone: newValue.code }); }}
						renderOption={(props, option) => (
							<List  {...props}>
								<ListItem>
									<ListItemText primary={option.name} />
								</ListItem>
							</List>
						)}
						options={field.zone}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('services.zone')}
								color="secondary"
								InputProps={{
									...params.InputProps,
								}}
								error={errors.zone ? true : false}
								helperText={errors.zone ? t('errors.required') : ''}
							/>
						)}
					/>
				</Grid>
			) : null}
			{serviceFields.seller_depot.show ? (
				<Grid item xs={10} md={6}>
					<Autocomplete
						disableClearable
						autoHighlight={true}
						getOptionLabel={(option) => option.name}
						onChange={(event, newValue) => { setForm({ ...form, seller_depot: newValue.code }); }}
						renderOption={(props, option) => (
							<List  {...props}>
								<ListItem>
									<ListItemText primary={option.name} />
								</ListItem>
							</List>
						)}
						options={field.depotSeller}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('services.seller_depot')}
								color="secondary"
								InputProps={{
									...params.InputProps,
								}}
							/>
						)}
					/>					
				</Grid>
			) : null}
			<Grid item xs={10} md={6}>
				<Autocomplete
					disableClearable
					autoHighlight={true}
					getOptionLabel={(option) => option.name}
					value={typeService}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					onChange={(event, newValue) => handleAutocompleteTypeService(newValue) }
					options={typeServices}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t('services.service_type')}
							color="secondary"
							InputProps={{
								...params.InputProps,
							}}
							error={errors.type_service ? true : false}
							helperText={errors.type_service ? t('errors.required') : ''}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={10} md={6}>
				<TextField
					fullWidth
					color="secondary"
					label={t('services.neighborhood')}
					value={form.neighborhood}
					onChange={e => setForm({ ...form, neighborhood: e.target.value })}
				/>
			</Grid>
			<Grid item xs={10} md={6}>
				<TextField
					fullWidth
					color="secondary"
					label={t('services.receiver')}
					value={form.receiver}
					onChange={e => setForm({ ...form, receiver: e.target.value })}
				/>
			</Grid>
			<Grid item xs={10} md={6}>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DatePicker
						value={field.dateService}
						label={t('services.date')}
						onChange={handleDateChange}
						renderInput={(params) => <TextField fullWidth color="secondary" {...params} />}
					/>
				</LocalizationProvider>
			</Grid>
			{serviceFields.load_time.show ? (
				<Grid item xs={10} md={6}>
					<TextField
						fullWidth
						color="secondary"
						label={t('services.lead_time')}
						value={form.load_time}
						onChange={e => setForm({ ...form, load_time: e.target.value })}
						error={errors.load_time ? true : false}
						helperText={errors.load_time ? t('errors.required') : ''}
					/>					
				</Grid>
			) : null}
			{serviceFields.time_windows.show ? (
				<Fragment>
					<Grid item xs={10} md={12}>{t('time_windows.title')}</Grid>
					<Grid item xs={10} md={12}>
						<Grid container spacing={2}>
							<Grid item xs={10} md={5}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopTimePicker
										label={t('time_windows.start_time')}
										color="secondary"
										value={field.time_window.start_time}
										onChange={(newValue) => handleTimeWindows(newValue,'start_time')}
										renderInput={(params) => <TextField className="clock" color="secondary" {...params} />}
										ampm={false}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={10} md={5}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopTimePicker
										label={t('time_windows.end_time')}
										color="secondary"
										value={field.time_window.end_time}
										onChange={(newValue) => handleTimeWindows(newValue,'end_time')}
										renderInput={(params) => <TextField className="clock" color="secondary" {...params} />}
										ampm={false}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={2}>
								<IconButton onClick={e => addTimeWindow()} color="secondary" sx={{ p: '10px' }} aria-label="time_windows" size="large">
									<AddCircleIcon />
								</IconButton>
							</Grid>
							<Grid item xs={12}>
								{form.time_windows.map((item,index) => (
									<Chip 
										color="secondary"
										sx={{ mr: 2 }}
										key={index}
										label={item.start_time+'-'+item.end_time}
										onDelete={() => deleteTimeWindow(index)}/>
								))}
								{errors.time_windows ? <Chip color='error' label={t('errors.required')}/> : null}
							</Grid>
						</Grid>
					</Grid>
				</Fragment>
			) : null}
			{cubing.length ? (
				<Fragment>
					<Grid item xs={10} md={12}> {t('services.cubing_variables')} </Grid>
					{cubing.map((item, index) => (
						<Grid item xs={6} key={item.id}>
							<TextField 
								fullWidth
								color="secondary"
								label={`${item.name} [${item.unit_measurement.initials}]`}
								value={cubing[index].value}
								onChange={e => handleChangeCubing(index, e.target.value) }
								error={errors[item.id] ? true : false}
								helperText={errors[item.id] ? t(`errors.cubing.${errors[item.id].type}`) : ''}
							/>
						</Grid> 
					))}
				</Fragment>
			) : null}
			{additionalFields.length ? (
				<Fragment>
					<Grid item xs={10} md={12}> {t('services.additional_fields')} </Grid>
					{additionalFields.map((item, index) => (
						<Grid item xs={6} key={item.id}>
							<AdditionalFields 
								type={item.field_type} 
								data={{ 
									index, 
									labelName:item.information[i18n.resolvedLanguage], 
									event: handleAdditionalField,
									value: additionalFields[index].value,
									options: item.dynamic_fields_options
								}}
								error={errors[item.id]}
							/>
						</Grid> 
					))}
				</Fragment>
			) : null}
			{serviceFields.description.show ? (
				<Grid item xs={10} md={12}>
					<TextField
						fullWidth
						color="secondary"
						label={t('services.notes')}
						multiline
						rows={4}
						value={form.description}
						onChange={(e) => { setForm({ ...form, description: e.target.value }) }}
					/>					
				</Grid>
			) : null}
			<Grid item xs={10} md={4} sx={{mb:4}}>
				<LoadingButton fullWidth color="secondary" variant="contained" loading={loading.submit} size="large" onClick={handleOnSubmit}>
					{t('btn.save')}
				</LoadingButton>
			</Grid>
		</Grid>
	)
}